import * as React from "react";
import LayoutPatient from "components/layout/patient";
import HeaderLarge from "components/section/headerLarge";
import SectionWrapper from "../../../components/section/sectionWrapper";
import { Row, Grid } from "rsuite";
import ContentfulPageQuestionOverviewFiltered from "../../../components/section/contentfulPageQuestionOverviewFiltered";
const IndexPage = () => {
  return (
    <LayoutPatient>
      <HeaderLarge
        title="Leefstijl, werk en voeding"
      />
      <SectionWrapper background>
        <Grid>
          <Row style={{ padding: "80px 0" }}>
            <ContentfulPageQuestionOverviewFiltered />
          </Row>
        </Grid>
      </SectionWrapper>
    </LayoutPatient>
  );
};

export default IndexPage;
